<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="list">
            <div class="item" @click="$router.push({ name: 'nonHeritageDetail', query: { id: item.id, type: 1 } })" v-for="(item, index) in list" :key="index">
                <img class="active" :src="item.picUrl" alt="" />
                <div class="info">
                    <div class="title">{{ item.name }}</div>
                    <div class="desc">{{ item.briefIntroduction || '' }}</div>
                </div>
            </div>
        </div>
        <el-pagination @size-change="getHeritageList" @current-change="getHeritageList" :current-page.sync="page" :page-sizes="[12, 24, 36, 48]" :page-size.sync="limit" layout="prev, pager, next,jumper" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
import { getHeritageList } from '@/api';
export default {
    name: 'index',
    data() {
        return {
            tabs: 0,
            limit: 12,
            page: 1,
            total: 0,
            list: [],
            nav: [
                {
                    title: '首页',
                    to: '/',
                },
                {
                    title: '非遗文化',
                    to: { path: '/nonHeritage' },
                },
                {
                    title: '非遗名录',
                },
            ],
        };
    },
    created() {
        this.getHeritageList();
    },
    methods: {
        getData() {
            console.log(this.limit, this.page);
        },
        getHeritageList() {
            let params = {
                pageSize: this.limit,
                pageNum: this.page,
                libraryId: localStorage.getItem('id'),
            };
            getHeritageList(params).then((res) => {
                console.log(res);
                this.list = res.rows;
                this.total = res.total;
            });
        },
    },
};
</script>

<style scoped lang="less">
.list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
    width: 100%;

    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-bottom: 24px;

        .active {
            width: 100%;
            height: 210px;
        }

        .info {
            position: relative;
            padding: 12px;

            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                color: #333333;
                width: 100%;
                .ellipsisLine(1);
            }

            .desc {
                font-size: 12px;
                line-height: 17px;
                color: #999999;
                width: 100%;
                margin-top: 12px;
                .ellipsisLine(2);
            }
        }
    }
}
</style>
